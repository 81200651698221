<template>
    <section>
        <base-card>
            <v-row wrap>
                <v-col
                        cols="12"
                        sm="12"
                        md="5"
                >
                    <v-img
                            :src="value.acf.athlete_image"
                    ></v-img>
                </v-col>
                <v-col
                        cols="12"
                        sm="12"
                        md="7"
                        class="pl-3 text-left "
                >
                    <h2
                            class="display-3 font-weight-light secondary--text mb-3 ml-2"
                            v-text="value.acf.athlete_firstName + ' ' + value.acf.athlete_lastName"
                    >

                    </h2>
                    <div v-for="(feature, i) in mainFeatures"
                         :key="i"
                    >
                        <div
                                class=".subtitle-1 font-weight-light mb-1 ml-4"
                                v-text="$t(`athlete.${feature.title}`)"
                        >
                        </div>
                        <div
                                class="display-1 font-weight-light mb-5 ml-4"
                        >
                            <flag v-if="feature.title === 'nationality'" :iso="feature.value" v-bind:squared=false />
                            <span v-if="feature.title !== 'nationality'">{{feature.value}}</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-3">
                <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        style="background-color: #d0131f"
                        class="elevation-3"
                >
                    <div
                            class="display-1 font-weight-regular white--text mb-5 pl-1 text-center"
                            style="cursor: pointer"
                    >
                        {{$t(`athlete.${bar[0].title}`)}}
                    </div>
                    <div
                            class="subtitle-1 font-weight-regular white--text mb-5 pl-1 text-center"
                            v-text="bar[0].value[currentLang]"
                    />
                </v-col>
                <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        style="background-color:#ab191d"
                        class="elevation-3"
                >
                        <div
                                class="display-1 font-weight-regular white--text mb-5 pl-1 text-center"
                                style="cursor: pointer"
                        >
                            {{$t(`athlete.${bar[1].title}`)}}
                        </div>
                        <div
                                class="subtitle-1 font-weight-regular white--text mb-5 pl-1 text-center"
                                v-text="bar[1].value[currentLang]"
                        />
                </v-col>
            </v-row>
            <v-row>
                <v-col
                        cols="12"
                        md="12"
                        class="mt-5"
                >
                    <div v-for="(feature, i) in features"
                         :key="i"
                    >
                        <div
                                class=".subtitle-1 font-weight-light mb-1 ml-4"
                                v-text="$t(`athlete.${feature.title}`)"
                        >
                        </div>
                        <div
                                class="display-1 font-weight-light mb-5 ml-4"
                                v-text="feature.value[currentLang]"
                        >
                        </div>
                    </div>
                </v-col>
            </v-row>
        </base-card>
    </section>
</template>

<script>
    import i18n from '@//plugins/i18n'

    export default {
        props: {
            value: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            expand: false,
            mainFeatures: [
                {
                    title: 'yearOfBirth',
                    value: null
                },
                {
                    title: 'nationality',
                    value: null
                },
                {
                    title: 'placeOfResidence',
                    value: null
                },
                {
                    title: 'memberSince',
                    value: null
                }
            ],
            features: [
                {
                    title: 'triathlonSince',
                    value: null
                },
                {
                    title: 'biggestEvent',
                    value: null
                },
                {
                    title: 'goals',
                    value: null
                },
                {
                    title: 'roleModel',
                    value: null
                },
                {
                    title: 'workout',
                    value: null
                },
                {
                    title: 'wish',
                    value: null
                },
                {
                    title: 'lucky',
                    value: null
                },
            ],
            bar: [
                {
                    title: 'strength',
                    value: null
                },
                {
                    title: 'weaks',
                    value: null
                }
            ]
        }),
        computed: {
            fullName() {
                let firstName = this.value.acf.athlete_firstName
                let space = ' '
                let lastName = this.value.acf.athlete_lastName
                let fullName = firstName += space += lastName
                return fullName
            },
            currentLang () {
                return i18n.locale
            }
        },
        mounted()
        {
            console.log(this.$vuetify.lang.current)
            this.mainFeatures[0].value = this.value.acf.athlete_yearOfBirth
            this.mainFeatures[1].value = this.value.acf.athlete_nation
            this.mainFeatures[2].value = this.value.acf.athlete_placeOfResidence.city
            this.mainFeatures[3].value = this.value.acf.athlete_memberSince

            this.features[0].value = this.value.acf.athlete_triSince
            this.features[1].value = this.value.acf.athlete_biggestEvent
            this.features[2].value = this.value.acf.athlete_goals
            this.features[3].value = this.value.acf.athlete_roleModel
            this.features[4].value = this.value.acf.athlete_workout
            this.features[5].value = this.value.acf.athlete_wish
            this.features[6].value = this.value.acf.athlete_lucky
            this.bar[0].value = this.value.acf.athlete_strength
            this.bar[1].value = this.value.acf.athlete_weaks
        }
    }
</script>
